body {
  margin: 0;
  font-family:BoldFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {

  box-sizing: border-box;

}


.white-background {
  background-color: var(--light);
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  background-color:var(--dark);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--light);
  border-radius: 5px;
}


@font-face {
  font-family: 'BoldFont'; 
  src: url('../public/mona-sans-cufonfonts-webfont/Mona-Sans-ExtraBoldWide.woff') ;
}

@font-face {
  font-family: 'LightFont'; 
  src: url('../public/mona-sans-cufonfonts-webfont/Mona-Sans-LightWide.woff') ;
}
@font-face {
  font-family: 'ParagraphFont'; 
  src: url('../public/mona-sans-cufonfonts-webfont/Mona-Sans-UltraLightWide.woff') ;
}
@font-face {
  font-family: 'ButtonFont'; 
  src: url('../public/mona-sans-cufonfonts-webfont/Mona-Sans-MediumWide.woff') ;
}

.full_row{
  width:100%  !important;

}
p{
  font-family: ParagraphFont;
line-height: 35px;

}
h1{
  font-family: LightFont;

}
:root {
  --light: #f1f1f1;
  --dark: #0c0c0c;
}


 
 
 .button_web {
  position: relative;
  /* margin: auto; */
  cursor: pointer;
  padding: 15px 18px;
  transition: all 0.2s ease;
  border: none;
  background: none;
  color: var(--light);
   position: relative;
  font-family: ButtonFont;
  font-size: 15px;
  letter-spacing:3px;
  will-change: transform;
  /* backdrop-filter: invert(100%); */
  transform-origin: center;
  mix-blend-mode: difference;
 }
 

 .button_web:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 50px;
  will-change: transform;
  transform-origin: center;
  mix-blend-mode: difference;
  width: 40px;
  height: 40px;
  transition: all 0.3s ease;
  background: var(--light);
 }
 
 .button_web:hover:before {
  width: 100%;
  background: var(--light);
  cursor: pointer;
 }
 .button_web:hover{
  cursor: pointer;
 }
 
 
 .button_web:active {
  transform: scale(0.95);
  cursor: pointer;
 }
