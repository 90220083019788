.chatko_container {
  width: 50%;
  margin: 5rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: absolute;
  right: 0;
  left: 0;
}
.lead_history_container {
  display: flex;
}
.leadai_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 5px;
}

.background_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.chatko_heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.chatko_heading > h1 {
  font-size: 45px;
}
.chatko_heading > p {
  font-size: 15px;
  color: var(--primary-color);
}

.chatko_textarea {
  height: 7rem;
  width: 99%;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: #fff;
  font-size: 20px;
  padding: 5px;
  font-family: "Exo", sans-serif;
}
.chatko_pre_container {
  margin-top: 1rem;
  width: 100%;
}

.chatko_pre {
  color: #fff;
  font-family: "Exo", sans-serif;
  font-size: 20px;
  margin: auto;
}

/* .chatko_result_copy{
  display: flex;
} */

.chatko_copy {
  /* position: absolute;
  top:15%;
  right: 18%; */
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
}
.chatko_buttons {
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.chatko_history {
  position: fixed;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5rem;
  width: 20%;
  height: 100%;
  border-right: solid 2px #1d1d1d;

}
.chatko_history > h1 {
  border-bottom: solid 3px #1d1d1d;
width: 100%;
text-align: center;
padding-bottom: 20px;

}
.chatko_history > ul {
  cursor: pointer;
  list-style: none;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 97%;
}
.chatko_history > ul > button {
  border: 1px solid #fff;
  padding: 20px;
  font-size: 18px;
  color: #eeeeee5c;
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: "Exo", sans-serif;
  cursor: pointer;
  background-color: transparent;
  overflow: hidden;
  text-align: left;

}
.chatko_history > ul > button:hover {
  color: #fff;
  background-color: #1a1a1a;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-1px);
}

/* tablet */
@media only screen and (max-width: 1439px) and (min-width: 769px) {
  .chatko_container {
    width: 80%;
  }
  .chatko_buttons {
    width: 88%;
  }
}

@media screen and (max-width: 768px) {
  .background_container {
    background: #232323;
  }
  .background_container > canvas {
    display: none;
  }
  .chatko_container {
    width: 100%;
    margin: auto;
    position: unset;
  }
  .chatko_textarea {
    width: 75%;
  }
  .chatko_buttons {
    width: 92%;
  }
  .chatko_history {
    position: unset;
    width: 100%;
    margin-bottom: 2rem;
  }
  .chatko_history > ul {
    width: 100%;
  }
  .chatko_copy {
    width: 100%;
  }

  .chatko_pre_container {
    width: 100%;
  }
  .chatko_heading {
    width: 100%;
    margin: 1rem auto 1rem auto;
  }
.lead_history_container{
  display: flex;
  flex-direction: column;

}
.chatko_textarea{

  width: 100%;
}


}
