.login_form {
  background: #f6f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* font-family: 'Montserrat', sans-serif; */
  height: 100vh;
}

.login_form_p {
  font-size: 18px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

.login_form_span {
  font-size: 18px;
}

.login_form_a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

.login_form_button {
  border-radius: 20px;

  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

.login_form_button:active {
  transform: scale(0.95);
}

.login_form_button:focus {
  outline: none;
}

.login_form_button_ghost {
  border: 1px solid #ffffff;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  background-color: transparent;
}

.login_form_form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}
.login_form_form h1 {
  font-size: 40px !important;
}

.login_form_form input {
  background-color: #eee;
  border: none;
  padding: 20px 15px;
  margin: 10px 0;
  width: 80%;
}

.container {
  background-color: #fff;

  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.container.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.container.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.container.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  /* background: #FF416C; */
  background-image: linear-gradient(to right, #555af2, #0c0c0c);
    display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}
.overlay h1 {
  font-size: 40px;
}
.container.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.container.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}
.x-icon{
  display: none;
}
/* Mobile Responsive */

@media (max-width: 768px) {
  .login_form {
    /* height: auto; */
  }
  
  .login_form_p {
    font-size: 16px;
  }
  
  .login_form_span {
    font-size: 16px;

  }
  #container > div.form-container.sign-up-container.active > form > span{
    color: #eee;
  }
  
  .login_form_a {
    margin: 10px 0;
  }
  
  .login_form_button {
    font-size: 14px;
    padding: 10px 35px;
  }
  
  .login_form_button_ghost {
    font-size: 14px;
    padding: 10px 35px;
  }
  
  .login_form_form {
    padding: 0 30px;
  }
  
  .login_form_form h1 {
    font-size: 30px !important;
 
  }
  #container > div.form-container.sign-up-container > form > h1{
    font-size: 20px !important;

    border:  2px solid #fff;
    padding: 5px 15px;
    border-radius: 35px;
    margin: -130px auto 90px auto;
  }
  .login_form_form input {
    width: 100%;
  }
  
  .container {
    /* height: auto; */
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding: 5rem auto;
  }
  
  .sign-in-container,
  .sign-up-container {
    width: 100%;
    position: static;
    opacity: 1;
    transform: translateX(0);
    transition: none;
  }
  .sign-in-container{
    height: 70%;
  }
  .sign-up-container {
    position: absolute;
    z-index: 5;
    top: 72%;
    transition: all 0.6s ease-in-out;
  }
  .sign-up-container.active {
    top:0;
  }
  
  .container.right-panel-active .overlay {
    transform: translateX(0);
  }
  .x-icon {
    display: none;
    font-size: 30px;
    position: absolute;
    top: 3%;
    left: 85%;
    cursor: pointer;
  }
  

  
  .overlay-container {
    position: relative;
    width: 100%;
    left: 0;
    transform: none;
    transition: none;
    display: none;
  }
  
  .overlay {
    width: 100%;
    height: auto;
    left: 0;
    transform: none;
    transition: none;
  }
  
  .overlay-panel {
    width: 100%;
    transform: none;
    transition: none;
  }
  
  .overlay-left,
  .overlay-right {
    transform: translateX(0);
  }

  #container > div.form-container.sign-up-container > form{

    background: -webkit-linear-gradient(to right, #583b97, #3171b8);
    background: linear-gradient(to right, #583b97, #3171b8);
padding-bottom: 5rem;


  }
  #container > div.form-container.sign-up-container > form > input[type=text]{
    background-color: #fff;
  }

  #container > div.form-container.sign-up-container > form >h1{

  color: #fff;
    
      }
}
